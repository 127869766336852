<template>
  <div class="w-full rounded-lg">
    <div class="flex relative items-center">
      <!-- Ad Meta -->
      <div class="absolute right-3 md:right-10 top-1/2 -translate-y-1/2">
        <div class="flex flex-col items-start justify-center">
          <div class="text-lg sm:text-xl font-bold text-bg_color textShadow">
            {{ ad.title }}
          </div>

          <div class="sm:text-lg text-bg_color font-normal textShadow">
            {{ ad.sub_title }}
          </div>
          <div class="h-3"></div>
          <button
            v-if="ad.button_title"
            class="bg-button_color rounded-xl text-sm sm:text-base text-text_alt_color py-1 px-3"
            @click="goToRelated"
          >
            {{ ad.button_title }}
          </button>
        </div>
      </div>

      <!-- Ad Image -->
      <div class="w-full" :class="`aspect-[${width / height}]`">
        <NuxtImg
          provider="cacheIpx"
          preset="modified"
          loading="lazy"
          :src="ad.image"
          :alt="ad.title"
          :width="width"
          :height="height"
          class="w-full h-full object-cover rounded-lg"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Ad } from '~~/composables/useMenuModel'
const { goToRelatedAd } = useAnnouncementStore()
const props = withDefaults(
  defineProps<{
    ad: Ad
    width: number
    height: number
  }>(),
  {
    width: 1800,
    height: 720
  }
)

function goToRelated() {
  goToRelatedAd(props.ad)
}
</script>
<style>
.textShadow {
  text-shadow: 1px 1px 2px rgba(var(--color-nav-color)),
    0 0 1em rgba(var(--color-text-color)),
    0 0 0.2em rgba(var(--color-text-color));
}
</style>
